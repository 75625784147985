/* Default styles */
.responsive-text {
  font-size: 1.5rem;
}

.responsive-p-text {
  font-size: 1rem;
}

.service-responsive-text-header {
  font-size: 1.5rem;
}

.service-responsive-text {
  font-size: 1rem;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  color: #221816; /* Use your desired color */
  border-radius: 50%; /* Optional: Add styling like rounded edges */
}

.carousel-control-prev-icon:hover,
.carousel-control-next-icon:hover {
  color: #1d1613; /* Darker shade for hover effect */
}

/* Mobile (Default) */
.image-column {
  max-width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.image-column img {
  max-width: 80%;
  height: 60vw;
  border-radius: 10px;
}

.image-column img:first-child {
  z-index: 1;
}

.image-column img:last-child {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.text-column {
  min-width: 100%;
}

/* Tablet */
@media (min-width: 768px) {
  .brands-container {
    margin-top: 15%;
  }
  .text-column {
    min-width: 100%;
  }

  .image-column {
    max-width: 80%;
  }

  .image-column img {
    min-width: 100%;
    height: 60vw;
  }

  .image-column img:last-child {
    top: 20%;
    left: 50%;
  }

  /* Responsive text */
  .responsive-text {
    font-size: 6rem;
  }

  .responsive-p-text {
    font-size: 2rem;
  }

  .service-responsive-text-header {
    font-size: 3rem;
  }

  .service-responsive-text {
    font-size: 1.3rem;
  }

  /* Adjust height and margin for smaller screens */
  div[style*='height: 100vh'] {
    height: auto; /* Adjust height for smaller screens */
  }
}

/* Laptop */
@media (min-width: 1024px) {
  .image-column {
    max-width: 100%;
  }

  .image-column img {
    min-width: 40%;
    height: 40vw;
  }

  .image-column img:last-child {
    top: 25%;
    left: 30%;
  }
}

/* Desktop */
@media (min-width: 1200px) {
  .responsive-text {
    font-size: 6rem;
  }

  .responsive-p-text {
    font-size: 2rem;
  }

  .service-responsive-text-header {
    font-size: 4rem;
  }

  .service-responsive-text {
    font-size: 1.3rem;
  }

  .image-column img:last-child {
    top: 25%;
    left: 30%;
  }
}
