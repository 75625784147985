.dark {
  color: #222;
}
.white {
  color: #fff;
}
.bg-white {
  background-color: #fff;
}
.bg-dark {
  background-color: #666;
}
.bg-gray {
  background-color: #e3e3e3;
}
.bg-red {
  background-color: #f03434;
}
.bg-green {
  background-color: #26a65b;
}
.block {
  display: block;
}
.flex {
  display: flex;
}
.w-auto {
  width: auto !important;
}
.w-100 {
  width: 100% !important;
}
.w-50 {
  width: 50%;
}
.w-55 {
  width: 55%;
}
.w-45 {
  width: 45%;
}
.w-60 {
  width: 60%;
}
.w-40 {
  width: 40%;
}
.w-48 {
  width: 48%;
}
.w-17 {
  width: 17%;
}
.w-18 {
  width: 18%;
}
.row {
  position: relative;
  border-bottom: 1px solid #e3e3e3;
}
.row__remove {
  display: flex;
  padding: 0;
  position: absolute;
  top: 10px;
  right: -20px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}
.row:hover .row__remove, .row:focus-within .row__remove {
  opacity: 1;
}
body {
  font-family: Nunito, sans-serif;
  font-size: 14px;
  color: #555;
  background: #f2f3f5;
  padding: 0;
  margin: 0;
}
* {
  box-sizing: border-box;
}
::placeholder {
  color: #aaa;
  opacity: 1;
}
input, textarea, select {
  font-family: Nunito, sans-serif;
  font-size: 14px;
  color: #555;
  background: transparent;
  border-radius: 3px;
  outline: 0;
}
select {
  outline: 0;
}
textarea {
  resize: vertical;
  background: transparent;
}
button.link {
  display: flex;
  align-items: center;
  background: transparent;
  border: 0;
  color: #428bca;
}
button.link:hover, button.link:focus {
  text-decoration: underline;
}
button.link:focus {
  outline: 1px dotted #66afe9;
  opacity: 1;
}
h1 {
  margin-top: 0;
  margin-bottom: 30px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.ml-30 {
  margin-left: 30px;
}
.p-4-8 {
  padding: 4px 8px;
}
.p-5 {
  padding: 5px;
}
.pb-10 {
  padding-bottom: 10px;
}
.right {
  text-align: right;
}
.center {
  text-align: center;
}
.bold {
  font-weight: 600;
}
.fs-20 {
  font-size: 20px;
}
.fs-30 {
  font-size: 30px;
}
.fs-45 {
  font-size: 45px;
}
.upper {
  text-transform: uppercase;
}
.icon {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  border: 0;
  border-radius: 50%;
}
.icon-absolute {
  position: absolute;
}
.icon-remove::before, .icon-remove::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 10px;
  left: 7px;
  top: 3px;
  background-color: #fff;
}
.icon-remove::before {
  transform: rotate(45deg);
}
.icon-remove::after {
  transform: rotate(-45deg);
}
.icon-add::before, .icon-add::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 10px;
  left: 7px;
  top: 3px;
  background-color: #fff;
}
.icon-add::after {
  transform: rotate(-90deg);
}
.app {
  margin: 30px auto 50px auto;
  width: 700px;
}
.input, .span, .select {
  display: inline-block;
  width: 98%;
  padding: 4px 12px 4px 0;
  margin-bottom: 1px;
  border: 1px dotted transparent;
  transition: background 0.1s ease-in-out, border-color 0.2s ease-in-out;
}
.input:hover, .select:hover, .input:focus, .select:focus {
  background: #fdf4db;
}
.input:focus, .select:focus {
  border-color: #66afe9;
}
.input.white:hover, .select.white:hover, .input.white:focus, .select.white:focus {
  color: inherit;
}
.invoice-wrapper {
  position: relative;
  background: #fff;
  padding: 40px 35px;
  box-shadow: 0 0 17px 0 rgba(16, 40, 73, 0.09);
}
.download-pdf {
  position: fixed;
  top: 100px;
  margin-left: -110px;
  width: 40px;
  height: 40px;
  transition: opacity 0.2s ease-in-out;
}
.download-pdf.loading {
  opacity: 0.3;
}
.download-pdf a {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
